<template>
    <v-dialog
        v-model="dialog"
        max-width="800"
    >

        <v-card>
            <v-form v-model="formValid" ref="form">
                <v-card-title class="primary">
                    <span class="white--text">Imprime hoja de registros</span>
                    <v-spacer/>
                    <v-icon color="white" @click="dialog=false">mdi-close-box</v-icon>
                </v-card-title>

                <v-card-actions>

                    <v-spacer/>
                        <v-btn color="primary" @click="print">Imprimir</v-btn>
                        <v-btn color="primary" @click="dialog=false">Cerrar</v-btn>
                </v-card-actions>

                <v-card-text>
                    <v-sheet>
                        <div id="printHojaEnf" :style='{"width":"21.6","height":"28cm", "border": "1px dotted gray"}'>
                            <table :style='{"width":"100%", "border-collapse": "collapse", "line-height": "normal"}'>
                                <thead>
                                    <tr>
                                        <td>
                                            <div>

                                                <div :style='{
                                                    "font-size":"8pt",
                                                    "line-height": "normal",
                                                    "display": "grid",
                                                    "grid-template-columns": "10% 57% 33%",
                                                    "margin":"0px 0px 10px 0px"

                                                }'>


                                                    <div>


                                                        <img
                                                            src="/images/header-03.png"
                                                            height="38px"
                                                        />
                                                    </div>

                                                    <div :style='{
                                                        "text-align":"center",
                                                        "font-size":"9pt",
                                                        "font-weight": "bold"
                                                    }'>
                                                        <div :style='{
                                                            "font-size":"6pt",
                                                            "text-align":"right",
                                                        }'>
                                                            {{datosInter.id}}
                                                        </div>
                                                        (OCSSA001463) HOSPITAL DE LA NIÑEZ OAXAQUEÑA<br/>
                                                        REGISTROS CLÍNICOS DE ENFERMERÍA
                                                    </div>

                                                    <div :style='{
                                                        "text-align":"right"
                                                    }'>

                                                        <img
                                                            src="/images/logo-hno-2020.jpg"
                                                            height="37px"
                                                        />
                                                    </div>
                                                </div>

                                                <div
                                                    :style='{
                                                        "background-color":$store.state.enfermeria.coloresTuro[turno],
                                                        "padding":"5px 5px 0px 5px",
                                                    }'
                                                >

                                                    <div
                                                        class="encabezado"
                                                        :style='{
                                                            "display": "flex",
                                                        }'>
                                                        <div >
                                                            ({{datosPac.pacienteId}}) {{datosPac.nombreCompleto}} - {{datosPac.edad}} ({{datosPac.fechaNacimiento}} <span v-if="datosPac.sexo=='m'">Hombre</span><span v-if="datosPac.sexo=='f'">Mujer</span>)
                                                        </div>

                                                        <div :style='{"flex": "20%", "text-align":"right", "padding": "0px 5px 0px 0px"}'>
                                                            <span>{{moment(estaFecha, 'DD-MM-YYYY').format('dddd MMMM D, YYYY')}} {{turno?datosTurnos[turno].text:null}}</span>
                                                        </div>
                                                        <div
                                                            :style='{
                                                                "height": "15px",
                                                                "width": "15px",
                                                                "background-color": turno?datosTurnos[turno].color:null,
                                                                "border-radius": "50%",
                                                                "margin":"5px 4px 0px 0px",
                                                            }'
                                                        ></div>
                                                    </div>
                                                    <div :style='{"padding":"5px", "background-color":"white",}'>
                                                        <datos-generales
                                                            :estaFecha="estaFecha"
                                                            :turno="turno"

                                                            :datosInter="datosInter"
                                                            :datosPac="datosPac"
                                                            :datosTurnos="datosTurnos"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                </thead>


                                <tbody>
                                    <tr>
                                        <td>

                                            <div
                                                v-for="(sec, id) in colsFex"
                                                :key="id"
                                                :style='{
                                                    "padding":"2px 4px 2px 4px",
                                                    "background-color":$store.state.enfermeria.coloresTuro[turno],
                                                    "page-break-inside": "avoid",
                                                }'
                                            >
                                                <div
                                                    :style='{
                                                        "display": "flex",
                                                        "flex-direction": "row",
                                                        "flex-wrap":"wrap",
                                                    }'
                                                >


                                                    <div
                                                        v-for="(sec2, id2) in sec"
                                                        :key="id2"
                                                        class="divZona"
                                                        :style='{
                                                            "margin":"2px",
                                                            "flex":sec2.width,
                                                            "height":sec2.alto,
                                                        }'
                                                    >
                                                        <div class="encabezado">
                                                            <span>{{sec2.label}}</span>
                                                        </div>
                                                        <div :style='{"padding":"5px"}'>
                                                            <component
                                                                :is="sec2.component"
                                                                :estaFecha="estaFecha"
                                                                :turno="turno"
                                                                :datos="datos[sec2.valores]"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                    </v-sheet>
                </v-card-text>

            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {

    components:{
        'datos-generales':()=>import('@/components/hospital/enfe/print/secciones/PrintDatosGenerales'),

        'grafica-signos':()=>import('@/components/hospital/enfe/print/secciones/PrintGraficaSignos'),
        'tabla-signos':()=>import('@/components/hospital/enfe/print/secciones/PrintTablaSignos'),
        'tabla-medicamentos':()=>import('@/components/hospital/enfe/print/secciones/PrintMedicamentos'),
        'tabla-soluciones':()=>import('@/components/hospital/enfe/print/secciones/PrintSoluciones'),
        'tabla-balances':()=>import('@/components/hospital/enfe/print/secciones/PrintBalanceLiq'),
        'tabla-escalas':()=>import('@/components/hospital/enfe/print/secciones/PrintEscalasRiesgos'),
        'tabla-nutrimental':()=>import('@/components/hospital/enfe/print/secciones/PrintNutri'),
        'tabla-valoraciones':()=>import('@/components/hospital/enfe/print/secciones/PrintValoraciones'),

        'tabla-sondas':()=>import('@/components/hospital/enfe/print/secciones/PrintSondas'),
        'tabla-oxigeno':()=>import('@/components/hospital/enfe/print/secciones/PrintOxigeno'),
        'tabla-intervenciones':()=>import('@/components/hospital/enfe/print/secciones/PrintIntervenciones'),
        'tabla-estudios':()=>import('@/components/hospital/enfe/print/secciones/PrintEstudios'),
        'tabla-datos':()=>import('@/components/hospital/enfe/print/secciones/PrintDatosObjSubj'),
        'tabla-diagnosticos':()=>import('@/components/hospital/enfe/print/secciones/PrintDiagnosticos'),

        'tabla-alta':()=>import('@/components/hospital/enfe/print/secciones/PrintAlta'),

        'tabla-firmas':()=>import('@/components/hospital/enfe/print/secciones/PrintFirmas'),
    },

    props:{
        entradaDialog:Boolean,
        estaFecha:String,
        internamiento:Number,
        turno:String,
        datos:Array,

        datosInter:Object,
        datosPac:Object,
    },
    data:()=>({
        datosHoja:{},
        formValid:false,

        horarios:{
            mat: [
                '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00',
            ],
            ves: [
                '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
            ],
            noc: [
                '21:00', '22:00', '23:00', '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00',
            ],
        },

        datosTurnos:{
            mat:{text: 'Matutino', color:'blue'},
            ves:{text: 'Vespertino', color:'green'},
            noc:{text: 'Nocturno', color:'red'},
        },

        colsFex:{
            0:[
                {id:10, label:"Gráfica se S.V. (_ _ _F.C.  ____: Temp)", component:'grafica-signos', valores:'grafica', alto:'5cm'},
                {flex:5, width:"30%", label:"Signos vitales y parms. de ventilación", component:'tabla-signos', valores:'somato'},
                {flex:5, width:"15%", label:"Valoración de riesgos", component:'tabla-escalas', valores:'escalas'},

            ],
            1:[
                {flex:5, width:"40%", label:"Balance de líquidos", component:'tabla-balances', valores:'liquidos'},
                {flex:5, width:"20%", label:"Medicamentos aplicados", component:'tabla-medicamentos', valores:'medicamentos'},
            ],
            2:[
                {flex:5, width:"40%", label:"Soluciones", component:'tabla-soluciones', valores:'soluciones'},
                {flex:5, width:"30%", label:"Sondas y catéteres", component:'tabla-sondas', valores:'sondas'},
                {flex:5, width:"18%", label:"Oxigenoterapia", component:'tabla-oxigeno', valores:'oxigeno'},
            ],
            3:[
                {flex:5, width:"40%", label:"Monitorización nutrimental", component:'tabla-nutrimental', valores:'nutrimental'},
                {flex:5, width:"20%", label:"Valoraciones", component:'tabla-valoraciones', valores:'valoraciones'},
                {flex:5, width:"20%", label:"Intervenciones", component:'tabla-intervenciones', valores:'intervenciones'},
            ],
            4:[
                {flex:5, width:"40%", label:"Estudios de gabinete", component:'tabla-estudios', valores:'gabinete'},
                {flex:5, width:"50%", label:"Datos objetivos y subjetivos", component:'tabla-datos', valores:'datosobj'},
            ],
            5:[
                {flex:10, width:"100%", label:"Diagnósticos y resultados", component:'tabla-diagnosticos', valores:'diagnosticos'},
            ],
            6:[
                {flex:10, width:"100%", label:"Plan de alta y recomendaciones", component:'tabla-alta', valores:'alta'},
                {flex:10, width:"100%", label:"Firmas de responsables", component:'tabla-firmas', valores:'firmas'},
            ],
        },

    }),
    methods:{
        async print(){
            await this.$htmlToPaper('printHojaEnf',{
                styles:[
                    '/css/hojaenf.css'
                ],
            })
        },

    },
    computed:{
        dialog:{
            get(){
                return this.entradaDialog
            },
            set(val){
                this.$emit('update:entradaDialog',val)
            },
        },

    },
    watch:{
    },
}
</script>

<style>

@media screen {
    @page {
        size: letter portrait;
    }
    .onlyPrint {
        display: none;
    }
    .divZona{
        background-color:white;
        margin: 3px 0px 3px 0px;
        border-radius: 10px;
    }

    .encabezado{
        padding: 0px 0px 2px 15px;
        border-radius: 10px 10px 0px 0px;
        background-color:#DCDCDC;
        font-weight: 400;
        font-size:10pt;

    }
    .wrapper {
        display: grid;
        grid-column-gap: 3px;
        grid-template-columns: 40% 60%;
        column-gap: 3px;
    }

    .col1{
        padding: 0px 2px 0px 4px;
    }
    .col2{
        padding: 0px 4px 0px 2px;
    }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card-title',{staticClass:"primary"},[_c('span',{staticClass:"white--text"},[_vm._v("Imprime hoja de registros")]),_c('v-spacer'),_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("mdi-close-box")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.print}},[_vm._v("Imprimir")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("Cerrar")])],1),_c('v-card-text',[_c('v-sheet',[_c('div',{style:({"width":"21.6","height":"28cm", "border": "1px dotted gray"}),attrs:{"id":"printHojaEnf"}},[_c('table',{style:({"width":"100%", "border-collapse": "collapse", "line-height": "normal"})},[_c('thead',[_c('tr',[_c('td',[_c('div',[_c('div',{style:({
                                                "font-size":"8pt",
                                                "line-height": "normal",
                                                "display": "grid",
                                                "grid-template-columns": "10% 57% 33%",
                                                "margin":"0px 0px 10px 0px"

                                            })},[_c('div',[_c('img',{attrs:{"src":"/images/header-03.png","height":"38px"}})]),_c('div',{style:({
                                                    "text-align":"center",
                                                    "font-size":"9pt",
                                                    "font-weight": "bold"
                                                })},[_c('div',{style:({
                                                        "font-size":"6pt",
                                                        "text-align":"right",
                                                    })},[_vm._v(" "+_vm._s(_vm.datosInter.id)+" ")]),_vm._v(" (OCSSA001463) HOSPITAL DE LA NIÑEZ OAXAQUEÑA"),_c('br'),_vm._v(" REGISTROS CLÍNICOS DE ENFERMERÍA ")]),_c('div',{style:({
                                                    "text-align":"right"
                                                })},[_c('img',{attrs:{"src":"/images/logo-hno-2020.jpg","height":"37px"}})])]),_c('div',{style:({
                                                    "background-color":_vm.$store.state.enfermeria.coloresTuro[_vm.turno],
                                                    "padding":"5px 5px 0px 5px",
                                                })},[_c('div',{staticClass:"encabezado",style:({
                                                        "display": "flex",
                                                    })},[_c('div',[_vm._v(" ("+_vm._s(_vm.datosPac.pacienteId)+") "+_vm._s(_vm.datosPac.nombreCompleto)+" - "+_vm._s(_vm.datosPac.edad)+" ("+_vm._s(_vm.datosPac.fechaNacimiento)+" "),(_vm.datosPac.sexo=='m')?_c('span',[_vm._v("Hombre")]):_vm._e(),(_vm.datosPac.sexo=='f')?_c('span',[_vm._v("Mujer")]):_vm._e(),_vm._v(") ")]),_c('div',{style:({"flex": "20%", "text-align":"right", "padding": "0px 5px 0px 0px"})},[_c('span',[_vm._v(_vm._s(_vm.moment(_vm.estaFecha, 'DD-MM-YYYY').format('dddd MMMM D, YYYY'))+" "+_vm._s(_vm.turno?_vm.datosTurnos[_vm.turno].text:null))])]),_c('div',{style:({
                                                            "height": "15px",
                                                            "width": "15px",
                                                            "background-color": _vm.turno?_vm.datosTurnos[_vm.turno].color:null,
                                                            "border-radius": "50%",
                                                            "margin":"5px 4px 0px 0px",
                                                        })})]),_c('div',{style:({"padding":"5px", "background-color":"white",})},[_c('datos-generales',{attrs:{"estaFecha":_vm.estaFecha,"turno":_vm.turno,"datosInter":_vm.datosInter,"datosPac":_vm.datosPac,"datosTurnos":_vm.datosTurnos}})],1)])])])])]),_c('tbody',[_c('tr',[_c('td',_vm._l((_vm.colsFex),function(sec,id){return _c('div',{key:id,style:({
                                                "padding":"2px 4px 2px 4px",
                                                "background-color":_vm.$store.state.enfermeria.coloresTuro[_vm.turno],
                                                "page-break-inside": "avoid",
                                            })},[_c('div',{style:({
                                                    "display": "flex",
                                                    "flex-direction": "row",
                                                    "flex-wrap":"wrap",
                                                })},_vm._l((sec),function(sec2,id2){return _c('div',{key:id2,staticClass:"divZona",style:({
                                                        "margin":"2px",
                                                        "flex":sec2.width,
                                                        "height":sec2.alto,
                                                    })},[_c('div',{staticClass:"encabezado"},[_c('span',[_vm._v(_vm._s(sec2.label))])]),_c('div',{style:({"padding":"5px"})},[_c(sec2.component,{tag:"component",attrs:{"estaFecha":_vm.estaFecha,"turno":_vm.turno,"datos":_vm.datos[sec2.valores]}})],1)])}),0)])}),0)])])])])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }